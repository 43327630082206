
import React from "react"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/aaaakshat/cm-web-fonts@latest/fonts.css"></link>
            </Helmet>
            {children}
        </>
    )
}
