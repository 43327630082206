import React, { useEffect } from "react";
import Layout from "../components/layout";
import { github, linkedin, email } from "../components/social";
import { initializeCanvas } from "../components/drawing";

const pageStyles = {
  fontFamily: "Computer Modern Serif, serif",
  backgroundColor: "#fff",
  color: "#222",
  textAlign: "center",
}

const centerDivStyles = {
  padding: "20px 60px 20px 60px",
  maxWidth: "800px",
  position: "absolute",
  left: "50%",
  top: "50%",
  WebkitTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fafafd",
  boxShadow: "5px 5px 8px #888"
}

const linkStyles = {
  color: "#457b9d",
}

const socialLinksStyle = {
  padding: "5px",
  display: "inline"
}

function consoleLog() {
  console.log(`%c
  What are the roots that clutch, what branches grow
  Out of this stony rubbish ? Son of man,
  You cannot say, or guess, for you know only
  A heap of broken images, where the sun beats,
  And the dead tree gives no shelter, the cricket no relief,
  And the dry stone no sound of water. Only
  There is shadow under this red rock,
  (Come in under the shadow of this red rock),
  And I will show you something different from either
  Your shadow at morning striding behind you
  Or your shadow at evening rising to meet you;
  I will show you fear in a handful of dust.
  %c
  (btw - use space bar to clear drawings, type anything else to spam drawings.)
  `, `
    font-family: 'american typewriter';
    font-size: 20px`,
    `
    font-family: 'american typewriter';
    font-size: 15px`);
}

const IndexPage = () => {
  useEffect(() => {
    consoleLog();
    initializeCanvas()
  });
  return (
    <>
      <canvas id="canvas" style={{ zIndex: -10 }}>
      </canvas>
      <Layout>
        <title>Jonathan Laflèche</title>
        <div style={pageStyles}>
          <div style={centerDivStyles} id="centerDiv">
            <h1>Jonathan Laflèche<br />Software Engineer</h1>
            <h2><a style={linkStyles} href="/notes">notes</a></h2>
            <div style={socialLinksStyle}>
              {linkedin}
            </div>
            <div style={socialLinksStyle}>
              {email}
            </div>
            <div style={socialLinksStyle}>
              {github}
            </div>
          </div>
        </div>
      </Layout >
    </>
  )
}

export default IndexPage
